@import '../../WebApp/styles/mixin.module.scss';

.text-field--with-margins {
  @include form-field__margins;
}

.text-field--with-margins :global(.WM-MuiFormHelperText-root) {
  @include form-field__margins--with-helper;
}

.text-field--hasHelper {
  color: red;
}
