@import '../WebApp/styles/shared.module.scss';

.cookies {
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  background: $color__primary;
  color: $color__text--primary;
}

.cookies__button {
  min-width: 200px;
  margin-top: 20px;
}

@media all and (min-width: $rwd__sm) {
  .cookies {
    flex-direction: row;
  }

  .cookies__button {
    margin-top: 0;
    margin-left: 20px;
  }
}
