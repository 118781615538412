@import '../../WebApp/styles/shared.module.scss';

$padding: 20px;
$padding__button: 10px;
$padding__field: 18px;
$button__max-width: 300px;

$button__reset--bg: #fff;
$button__reset--border: #bdb9b5;

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 100%;
  padding-top: $padding;
}

.header__text {
  font-weight: 700;
}

.close-icon {
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form__field--full-width {
  flex: 100%;
}

.filters__container--primary {
  background-color: $filters__bg--primary;
}

.filters__container--advanced {
  background-color: $filters__bg--advanced;
}

.filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: $padding;
}

.field {
  margin: 38px 0 0 0;
  padding: 0;
  flex-basis: 100%;
  width: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.field--radio {
  margin-top: 20px;
}

.form__distilled,
.form__bottled,
.form__abv,
.form__size {
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: calc(#{$padding__field} / 4);
  }

  > :last-child {
    margin-left: calc(#{$padding__field} / 4);
  }
}

.form__active__label {
  min-width: 150px;
}

.form__buttons {
  padding: 0 0 calc(#{$padding} - #{$padding__button}) 0;
}

.form__buttons--primary {
  background-color: $filters__bg--primary;
}

.form__buttons--advanced {
  background-color: $filters__bg--advanced;
}

.buttons__container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
}

.buttons__button {
  margin-bottom: $padding__button;
  flex: 100%;
  width: 100%;
  text-transform: none !important;
}

.buttons__button--reset {
  background-color: $button__reset--bg;
  border: 1px solid $button__reset--border;
}

.buttons__button--filters {
  color: $color__primary;
  text-align: center;
  line-height: 40px;
}

@media screen and (min-width: $rwd__sm) {
  .filters {
    flex-direction: row;
  }

  .form__field {
    &:not(.form__field--full-width) {
      flex: 50%;
      flex-grow: 0;
    }

    &:nth-child(odd):not(.form__field--full-width) {
      padding-left: 0;
      padding-right: calc(#{$padding__field} * 0.5);
    }

    &:nth-child(even):not(.form__field--full-width) {
      padding-left: calc(#{$padding__field} * 0.5);
      padding-right: 0;
    }
  }

  .buttons__container {
    flex-direction: row;
  }

  .buttons__button--reset {
    margin-right: $padding__field;
  }

  .buttons__button--submit {
    margin-left: $padding__field;
  }

  .buttons__button--filters {
    position: absolute;
    top: 0;
    right: 20px;
    width: unset;
    line-height: initial;
  }

  .buttons__button:not(.buttons__button--filters) {
    flex: 50%;
    width: 50%;
  }

  .buttons__button--primary {
    max-width: $button__max-width;
  }
}

@media screen and (min-width: $rwd__lg) {
  .form__field {
    &:not(.form__field--full-width) {
      flex: 25%;
      flex-grow: 0;
    }

    &:nth-child(4n-3):not(.form__field--full-width) {
      padding-left: 0;
      padding-right: calc(#{$padding__field} * 0.75);
    }

    &:nth-child(4n-2):not(.form__field--full-width) {
      padding-left: calc(#{$padding__field} * 0.25);
      padding-right: calc(#{$padding__field} * 0.5);
    }

    &:nth-child(4n-1):not(.form__field--full-width) {
      padding-left: calc(#{$padding__field} * 0.5);
      padding-right: calc(#{$padding__field} * 0.25);
    }

    &:nth-child(4n):not(.form__field--full-width) {
      padding-left: calc(#{$padding__field} * 0.75);
      padding-right: 0;
    }
  }

  .buttons__button {
    max-width: $button__max-width;
  }

  .buttons__button--filters {
    right: 0;
  }
}
