@import '../WebApp/styles/shared.module.scss';

.footer {
  background: $color__black;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.company {
  margin-bottom: 40px;
  color: $color__gray1;
  font-size: $text__sm;
}

.logo {
  height: 18px;
  margin-bottom: 20px;
}

.social {
  margin-bottom: 40px;
  display: none !important;
}

.social__icon {
  margin: 0 5px;
}

.links {
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;
}

.newsletter {
  max-width: 400px;
}

.newsletter__heading {
  margin-bottom: 20px;
  color: $color__default;
  font-size: $text__md;
}

.newsletter__text {
  color: $color__gray1;
  margin-bottom: 40px;
}

.newsletter__checkbox {
  color: $color__gray1;
}

.newsletter__label {
  color: $color__default;
}

.company__line--first {
  margin: 10px 0;
  display: block;
  font-size: $text__md;
  font-weight: 500;
}

.list__item {
  margin-bottom: 12px;
}

@media all and (min-width: $rwd__sm) {
  .links {
    padding: 0;
    flex-direction: row;
  }
}

@media all and (min-width: $rwd__md) {
  .footer {
    flex-direction: row;
    padding: 60px 100px;
    justify-content: space-evenly;
  }

  .links {
    padding: 0;
    margin: 48px 20px 40px 20px;
    flex-direction: row;
  }
}

.social-icons {
  margin-top: 40px;
}

.social-icons__list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.share-icons__item {
  margin-left: 24px;
}

.social-icon__icon {
  width: 24px;
  height: 24px;
}