@import '../WebApp/styles/shared.module.scss';

.header__logo {
  width: 96px;
}

.logo__dialog {
  height: 22px;
}

@media all and (min-width: $rwd__xs) {
  .header__logo {
    width: 146px;
    height: 30px;
  }
}
