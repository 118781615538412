@import '../../WebApp/styles/shared.module.scss';

.offers {
  display: flex;
  flex-direction: column;
}

.mini-profile {
  margin-bottom: 10px;
}

.mass-actions {
  margin-top: 0;
  width: 100%;
  max-width: 300px;
}

@media all and (min-width: $rwd__md) {
  .content--mine {
    margin-left: 44px;
  }
}

.offers__headline {
  margin-bottom: 20px;
}

.offers__list {
  display: flex;
  flex-direction: column;
}

.loading {
  display: block;
  margin: 0 auto;
}

.buttons {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button--show-all {
  margin-left: 12px;
  &:hover {
    background-color: transparent;
  }
}

.button--show-more:hover {
  background-color: transparent;
}

.remove-popup {
  padding: 20px;
}

.remove-popup__content {
  margin-top: 30px;
}

.remove-popup__navi {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

.loading-popup {
  min-width: 280px;
}

.loading-popup__content {
  text-align: center;
}

.loading-popup__spinner {
  margin-top: 2rem;
}

.search__container {
  margin-bottom: 20px;
  width: 100%;
  max-width: 445px;
  text-align: right;
}

.search__show-all {
  line-height: 40px;
}
