@import './shared.module.scss';

:global {
  .WM-MuiButton-root {
    text-transform: capitalize;
  }

  h1 {
    font-size: $text__4xl;
    font-weight: 500;
  }

  h2 {
    font-size: $text__3xl;
    font-weight: 500;
  }

  h3 {
    font-size: $text__2xl;
    font-weight: 900;
  }

  h4 {
    font-size: $text__xl;
    font-weight: 500;
  }

  h5 {
    font-size: $text__lg;
    font-weight: 500;
  }

  h6 {
    font-size: $text__md;
    font-weight: 500;
  }

  .WM-MuiFormControl-fullWidth > .WM-MuiButton-root {
    width: 100%;
  }
  .WM-PrivateNotchedOutline-legendLabelled.WM-PrivateNotchedOutline-legendNotched * {
    display: none !important;
  }
  .WM-MuiRadio-root {
    padding-right: 4px;
  }
}
