@import '../WebApp/styles/shared.module.scss';

.contact-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contact__field__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media all and (min-width: $rwd__sm) {
  .contact__field--half {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
  }

  .contact__field--name {
    order: 1;
    margin-right: 10px;
  }

  .contact__field--phone {
    order: 2;
    margin-left: 10px;
  }

  .contact__field--email {
    order: 3;
    margin-right: 10px;
  }

  .contact__field--topic {
    order: 4;
    margin-left: 10px;
  }
}

.help__container {
  display: flex;
  width: 100%;
}

.help {
  padding: 10px;
  background: $color__default;
  border-radius: $border__radius;
  color: $color__black;
  display: flex;
  align-items: flex-start;
}

.help--upload {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.help__text {
  margin-left: 5px;
}

.upload__main-label {
  margin-top: 20px;
  font-weight: 500;
}

.upload__container {
  width: 100%;
}

.upload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.captcha__container {
  width: 100%;
  margin-bottom: 1rem;
}

.contact__tos {
  width: 100%;
}

.button-container {
  display: block;
  margin-top: 2rem;
  width: 100%;
}

.button-container__button--add {
  width: 100%;
}

@media all and (min-width: $rwd__sm) {
  .button-container__button--add {
    max-width: 280px;
  }
}
