@import '../WebApp/styles/shared.module.scss';

.chat {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
}

.chat__menu {
  margin: 40px 0 20px 0;
}

.menu__item {
  margin-right: 24px;
}

.menu__item--disabled:hover {
  cursor: default;
  text-decoration: unset;
}
.chat__conversations-box,
.chat__conversations-box--empty {
  display: flex;
  min-height: 400px;
}

.chat__conversations-box--empty {
  background: $color__default;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.conversations-box--empty__headline {
  display: flex;
  flex-direction: row;
}

.conversations-box--empty__alert {
  font-weight: 500;
  color: $color__gray1;
  margin-top: 10px;
}

.chat__conversations-box {
  display: flex;
  flex-direction: column;
}

.conversations-box__head {
  display: flex;
  border-bottom: 1px solid $color__default--dark;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.head__user,
.head__date,
.head__offer {
  color: $color__gray1;
  font-size: $text__sm;
}

.head__user {
  padding-left: 10px;
  width: 80px;
  margin-right: 10px;
}

.head__date {
  width: 120px;
  display: none;
}

.head__offer {
  flex-grow: 1;
}

@media all and (min-width: $rwd__md) {
  .head__user {
    padding-left: 17px;
    width: 152px;
    margin-right: 0;
  }
  
  .head__date {
    display: block;
  }
}
