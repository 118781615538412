@import '../WebApp/styles/shared.module.scss';

:global {
  .slick-list {
    margin: 0;
    width: 100%;
  }
}

.slider {
  margin-bottom: 16px;
}

.slide {
  margin: 0;
  padding: 0;
}

.slide__image {
  width: 100%;
  height: auto;
}

.slide__link {
  cursor: pointer;
}

.dots {
  position: absolute;
  margin: 0;
  bottom: 40px;
  width: 100%;
  list-style: none;
  text-align: center;
}

.dots > .dots__item {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 100%;
  background: #fff;

  &.dots__item--active {
    background: $color__primary;
  }

  > button::before {
    display: none;
  }
}
