@import '../../../WebApp/styles/shared.module.scss';

.verification {
  margin-top: 20px;
}

.verification__headline {
  margin-bottom: 20px;
}

.verification__account-status {
  font-weight: 500;
  margin-bottom: 5px;
}

.account-status__row {
  background: $color__default;
  padding: 12px;
  display: flex;
  position: relative;
}

.verify-link {
  position: absolute;
  bottom: -24px;
  right: 1px;
}

.status-row__icon {
  margin-right: 5px;
}

.status-row__text {
  flex-grow: 1;
}

.status-row__not-required {
  color: $color__gray1;
}

.account-status__row--second {
  margin: 10px 0 40px 0;
}

.verification__hint {
  line-height: 21px;
  margin-bottom: 40px;
}

.transfer__headline {
  font-weight: 500;
  margin-bottom: 5px;
}

.transfer__input {
  padding-bottom: 0;
  margin-bottom: 5px;
  color: $color__gray2;
  border: 0;
  background: unset;
}

.transfer__input :global(.WM-MuiInput-underline:before) {
  content: unset;
}

.bank__headline {
  margin-top: 20px;
  font-weight: 500;
}

.company__headline {
  font-weight: 500;
  margin-top: 20px;
}
