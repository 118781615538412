@import '../../components/WebApp/styles/shared.module.scss';

.catalog-page {
  padding-top: 0;
}

.catalog-page__content {
  padding-left: 20px;
  padding-right: 20px;
}

@media all and (min-width: $rwd__sm) {
  .catalog-page {
    padding-top: 40px;
  }
}

@media all and (min-width: $rwd__md) {
  .catalog-page__content {
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
  }
}

@media screen and (min-width: $rwd__lg) {
  .catalog-page__content  {
    padding-left: 0;
    padding-right: 0;
  }
}
