@import '../WebApp//styles/shared.module.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background: #fff;
  border: 1px solid #aaa;
  box-shadow: 24px;
  padding: 40px;
}

.title {
  text-align: center;
}

.subtitle {
  padding: 20px 0;
}

.button__container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.button--save {
  width: 220px;
}
