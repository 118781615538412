@import '../../WebApp/styles/shared.module.scss';

$padding: 20px;
$padding__field: 18px;
$padding__button: 10px;
$button__max-width: 300px;

.filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: $padding;
}

.form__field--search {
  margin-bottom: 16px;
}

@media screen and (min-width: $rwd__lg) {
  .form__field--search {
    margin-top: 16px;
  }
}

@media screen and (min-width: $rwd__sm) {
  .filters {
    flex-direction: row;
  }

  .form__field {
    flex: 50%;
    flex-grow: 0;

    &:not(.form__field--search) {
      width: 50%;

      &:nth-child(odd) {
        padding-left: 0;
        padding-right: calc(#{$padding__field} * 0.5);
      }

      &:nth-child(even) {
        padding-left: calc(#{$padding__field} * 0.5);
        padding-right: 0;
      }
    }
  }
}

.field {
  margin: 38px 0 0 0;
  padding: 0;
  flex-basis: 100%;
  width: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.form__buttons {
  padding: 0 0 calc(#{$padding} - #{$padding__button}) 0;
}

.buttons__container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
}

.buttons__button {
  margin-bottom: $padding__button;
  flex: 100%;
  width: 100%;
  text-transform: none !important;
}

@media screen and (min-width: $rwd__sm) {
  .buttons__button--submit {
    margin-left: $padding__field;
  }

  .buttons__button--primary {
    max-width: $button__max-width;
  }
}

@media screen and (min-width: $rwd__sm) {
  .buttons__button {
    max-width: $button__max-width;
  }
}
