@import '../WebApp/styles/shared.module.scss';

@mixin action_icon {
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.arrow {
  position: absolute;
  display: block;
  top: calc(50% - 15px);
  z-index: 128;
  width: 30px;
  height: 30px;
  @include action_icon;
}

.arrow--prev {
  left: 20px;
  background-image: url('/public/icons/arrow_prev.svg');
}

.arrow--next {
  right: 20px;
  background-image: url('/public/icons/arrow_next.svg');
}
