@import '../../components/WebApp/styles/shared.module.scss';

.container {
  display: block;
  margin: 0 auto;
  max-width: 590px;
}

.heading {
  margin-bottom: 2rem;
}

.item__body {
  margin: 10px 0 40px 0;
}
