@import '../WebApp/styles/shared.module.scss';

@mixin contact__label--revealed {
  margin-left: 0;
  justify-content: center;
  width: 100%;
}

.offer {
  min-height: 200px;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

.offer__slider {
  max-width: 540px;
  max-height: 440px;
  margin: 0 auto 20px auto;
}

.offer__title {
  margin-bottom: 20px;
  text-align: left;
  text-transform: uppercase;
  padding: 0 10px;
}

.offer__subtitle {
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  padding: 0 10px 0 0;
}

.offer__swap {
  font-size: $text__sm;
  margin-top: 5px;
}

.offer__price {
  text-align: left;
  margin-bottom: 5px;
}

.offer__seller {
  background: $color__default;
  padding: 10px;
  border-radius: $border__radius;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.seller__headline {
  font-weight: 500;
  font-size: $text__lg;
  margin: 0 10px 10px 10px;
}

.seller__container {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.seller__avatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: $color__default;
  margin-right: 25px;
  display: block;
}

.seller__name-container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.name-container__user-box {
  display: flex;
  flex-direction: row;
  position: relative;
  left: -22px;
}

.name-container__verification {
  color: $color__gray1;
}

.user-box__name {
  font-size: $text__md;
  color: $color__black;
  margin-left: 5px;
}

.offer__details {
  padding: 0 10px;

  margin-bottom: 40px;
}

.offer__left {
  grid-row: 2;
  grid-column: 1/2;
}

.details__heading {
  margin: 20px 0 10px 0;
  text-align: left;
}

.details__list {
  display: table;
}

.details__item {
  display: table-row;
}

.details__attr,
.details__value {
  display: table-cell;
}

.details__value {
  font-size: $text__md;
}

.details__attr {
  padding: 0 10px 5px 0;
  font-size: $text__md;
  min-width: 190px;
}

.details__link {
  margin-top: 20px;
  display: inline-block;
}

.description__heading {
  margin: 20px 10px 10px 10px;
  text-align: left;
}

.description__text {
  white-space: pre-wrap;
  overflow: hidden;
  margin: 0 10px 48px 10px;
}

.offer__gallery {
  margin: 0 auto 10px auto;
  max-width: 540px;
}

.gallery__image {
  max-height: 540px;
  max-width: 100%;
  width: auto !important;
  object-fit: contain;
}

.gallery__image--inactive {
  filter: grayscale(100%);
}

.offer__info {
  margin-top: 20px;
}

.offer__info__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offer__badges, .offer__actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.offer__label--last-call, .offer__label--hidden {
  margin: 0 auto 10px 10px;
  width: 100px;
  font-weight: bold;
  text-transform: uppercase;
}

.offer__basic {
  display: flex;
  flex-flow: row wrap;
  margin: 0 10px;
  justify-content: space-between;
}

.basic__left {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.basic__right {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.basic__expiration,
.basic__availability {
  margin-bottom: 5px;
}

.basic__expiration {
  text-align: right;
}

.basic__availability {
  display: flex;
  flex-direction: row;
}

.availability__icon {
  display: block;
  height: 8px;
  margin-top: 6px;
}

.availability__name {
  margin-left: 4px;
}

.basic__buy-now {
  flex: 1 100%;
  display: flex;
  margin-top: 16px;
}

.buy-now__link {
  display: block;
  width: 100%;
}

.buy-now__button {
  width: 100%;
  height: 40px;
  justify-content: center;
}

.buy-now__icon {
  height: 16px;
  width: 16px;
}

.offer__divider {
  margin: 20px 10px;
}

.contact__icon {
  height: 16px;
  width: 16px;
}

.contact {
  margin: 40px 0 0 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.contact__label {
  width: 60%;
  margin-left: 40%;
}

.contact__button {
  width: 100%;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.contact__label--revealed {
  @include contact__label--revealed;
}

.seller {
  display: flex;
  flex-direction: column;
}

.offer__contact {
  margin: 0 10px;
}

.offer__adult-only {
  margin: 0 20px;
  padding: 10px;
  background: $color__default;
  border-radius: $border__radius;
  color: $color__black;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  font-size: $text__sm;
}

.adult-only__icon {
  position: absolute;
  top: -16px;
  left: -16px;
}

@media all and (min-width: $rwd__sm) {
  .contact__label {
    width: 50%;
    margin-left: 45%;
  }
  .contact__label--revealed {
    @include contact__label--revealed;
  }
}

@media all and (min-width: $rwd__md) {
  .offer__info {
    margin-top: 0;
  }

  .offer__basic {
    margin-right: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }

  .basic__left {
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .basic__right {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .basic__buy-now {
    align-items: flex-end;
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .buy-now__button {
    width: 220px;
  }

  .contact__label {
    width: 50%;
    margin-left: 33%;
  }

  .contact__label--revealed {
    @include contact__label--revealed;
  }

  .contact {
    margin: 0;
    padding: 0;
    width: 220px;
  }

  .offer__slider {
    height: 440px !important;
    max-height: 440px;
  }

  .seller__data {
    flex-grow: 1;
  }

  .seller {
    flex-direction: row;
  }

  .seller__other-items {
    text-align: left;
  }

  .offer {
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-rows: auto 1fr auto;
  }

  .offer__info {
    grid-column: 3;
    grid-row: 1/4;
  }

  .offer__price {
    text-align: left;
  }

  .description__heading,
  .details__heading {
    text-align: left;
  }

  .offer__description {
    grid-row: 3;
    grid-column: 1/2;
  }

  .offer__contact {
    margin-right: 0;
  }
}
