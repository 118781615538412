@import '../WebApp/styles/shared.module.scss';

.header {
  display: flex;
  justify-content: flex-end;
}

.header__logo {
  display: flex;
  flex-grow: 1;
}

.header__close {
  font-size: $text__3xl;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 32;
}

.dialog {
  padding: 25px 15px;
  max-height: 100vh;
}
