.menu {
  display: flex;
  cursor: pointer;
}

.menu__label {
  margin-left: 5px;
}

.popover {
  min-width: 300px;
}

.head__item-icon {
  min-width: unset;
  margin: 8px 10px 8px 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    max-width: 16px;
    max-height: 16px;
  }
}

.item__icon {
  height: 16px;
  width: 16px;
}

.menu__avatar {
  height: 32px;
  width: 32px;
}

.menu__badge {
  top: 14px;
  right: -20px;
}
