@import '../WebApp/styles/shared.module.scss';

.main {
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.main--normal {
  padding-top: 118px;
}

.main--simplified {
  padding-top: 58px;
}

.content {
  padding: 40px 0 40px 0;
  flex-grow: 1;
}

.content--sides-padding {
  padding: 40px 10px 40px 10px;
}

@media all and (min-width: $rwd__xs) {
  .main--normal {
    padding-top: 127px;
  }
}

@media all and (min-width: $rwd__sm) {
  .main--normal {
    padding-top: 77px;
  }

  .main--simplified {
    padding-top: 77px;
  }
}
