@import '../../WebApp/styles/shared.module.scss';

.arrow {
  border: solid $color__primary;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
}

.arrow--prev {
  transform: rotate(135deg);
}

.arrow--next {
  transform: rotate(-45deg);
}
