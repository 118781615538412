@import '../WebApp/styles/shared.module.scss';

@media all and (min-width: $rwd__sm) {
  .catalog__container {
    display: grid;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.container__results {
  margin: 20px 0;
}

.catalog__heading {
  margin: 0 10px;
}

.heading__container {
  display: flex;
  flex-direction: column;
}

.heading {
  margin-bottom: 10px;
}

.sort-by__container {
  width: 100%;
}

.catalog__items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.catalog__item {
  margin: 0 0 20px 0;
  width: 100%;
}

@media all and (min-width: $rwd__sm--catalog) {
  .catalog__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;
  }

  .catalog__item {
    height: 100%;
    max-width: 280px;
    margin: 0 auto;
  }
}

@media all and (min-width: $rwd__sm) {
  .catalog__items {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: 1;
    grid-column: 2/4;
  }

  .heading__container {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .heading {
    margin-bottom: 0;
  }

  .sort-by__container {
    max-width: 300px;
  }
}

@media all and (min-width: $rwd__md) {
  .catalog__items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.button-show-more {
  width: 100%;
  max-width: 300px;
  margin: 60px auto 90px auto;
  display: block;
}
