//RWD breakpoints - not sync with mateiral at the moment
$rwd__xs: 400px;
$rwd__sm: 768px;
$rwd__md: 1024px;
$rwd__lg: 1200px;

//rwd exceptions
$rwd__sm--catalog: 600px;
$rwd__lg--home: 1310px;

//fonts - base size = 14px
//12px
$text__sm: 0.85rem;
//14px
$text__md: 1rem;
//18px
$text__lg: 1.28rem;
//20px
$text__xl: 1.42rem;
//24px
$text__2xl: 1.71rem;
//26px
$text__3xl: 1.85rem;
//30px
$text__4xl: 2.14rem;

//value vars
$field__width: 200px;
$border__radius: 3px;

//Theme vars
$color__default: #f2f2f2;
$color__default--dark: #d9d9d9;
$color__default--active: #bdb9b5;
$color__text--default: #383838;

$color__primary: #f2411b;
$color__primary--dark: #d93a18; //dark is for hover
$color__primary--active: #bf3315;
$color__text--primary: #ffffff;

$color__secondary: #f2f2f2;
$color__secondary--dark: #d9d9d9;
$color__secondary--active: #bdb9b5;
$color__text--secondary: #bf3315;

$color__gray1: #8a8784;
$color__gray2: #383838; //dup
$color__gray3: #bdb9b5; //dup
$color__gray4: #8a8784; //dup
$color__gray5: #d9d9d97c;

$color__black: #232324;
$color__disabled: #f2f2f2;
$color__error: #ff0001;

$notify__bg--success: #72d32f;
$notify__bg--warning: #efac00;
$notify__bg--error: #e51313;
$notify__bg--info: #64c1e1;

$filters__bg--primary: #fff;
$filters__bg--advanced: #fef7f6;

$spacing-unit: 8px;

:export {
  rwd__xs: $rwd__xs;
  rwd__sm: $rwd__sm;
  rwd__md: $rwd__md;
  rwd__lg: $rwd__lg;
  rwd__sm--catalog: $rwd__sm--catalog;
  text__sm: $text__sm;
  text__md: $text__md;
  text__lg: $text__lg;
  text__xl: $text__xl;
  text__2xl: $text__2xl;
  text__3xl: $text__3xl;
  text__4xl: $text__4xl;

  field__width: $field__width;
  border__radius: $border__radius;

  color__default: $color__default;
  color__default--dark: $color__default--dark;
  color__default--active: $color__default--active;
  color__text--default: $color__text--default;

  color__primary: $color__primary;
  color__primary--dark: $color__primary--dark;
  color__primary--active: $color__primary--active;
  color__text--primary: $color__text--primary;

  color__secondary: $color__secondary;
  color__secondary--dark: $color__secondary--dark;
  color__secondary--active: $color__secondary--active;
  color__text--secondary: $color__text--secondary;

  color__gray1: $color__gray1;
  color__gray2: $color__gray2;
  color__black: $color__black;
  color__disabled: $color__disabled;
  color__error: $color__error;
  color__gray3: $color__gray3;
  color__gray4: $color__gray4;
  color__gray5: $color__gray5;

  notify__bg--success: $notify__bg--success;
  notify__bg--info: $notify__bg--info;
  notify__bg--warning: $notify__bg--warning;
  notify__bg--error: $notify__bg--error;

  filters__bg--primary: $filters__bg--primary;
  filters__bg--advanced: $filters__bg--advanced;

  spacing-unit: $spacing-unit;
}
