@import '../WebApp/styles/shared.module.scss';

.container {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  z-index: 128;
}

.header {
  padding: 18px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header__logo-box {
  flex-grow: 1;
}

.header__search__root {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 100%;
  margin-top: 20px;
}

.header__search__form {
  width: 100%;
}

.header__links {
  margin: 0;
  display: flex;
  place-content: flex-end;
  margin-right: 10px;
}

@media all and (min-width: $rwd__sm) {
  .header__logo-box {
    flex: 0 0 170px;
    order: 1;
  }

  .header__links {
    flex: 0 0 auto;
    order: 3;
  }

  .header__menu {
    flex: 0 0 auto;
    order: 4;
  }

  .header__search__root {
    flex: 1;
    order: 2;
    margin: unset;
    justify-self: center;
  }

  .header__search__form {
    max-width: 400px;
  }
}

@media all and (min-width: $rwd__md) {
  .header__search__form {
    max-width: 500px;
  }
}
