@import '../../components/WebApp/styles/shared.module.scss';

.new-user {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media all and (min-width: $rwd__md) {
  section.forgot-password-page {
    padding-left: 120px;
    padding-right: 120px;
  }
}
