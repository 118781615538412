@import '../../WebApp/styles/shared.module.scss';

.helper-text {
  margin-top: -6px;
}

.label {
  color: $color__gray2 !important;
  transform: translate(0, -18px) scale(1);
  font-weight: 500;
}
