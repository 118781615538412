@import '../WebApp/styles/shared.module.scss';

.dialog__container {
  padding-bottom: 120px;
}

.offer__heading {
  margin: 0 0 20px 0;
}

:global(.isMobile) .offer__heading {
  margin-top: 20px;
}

.offer__basic-info {
  margin-bottom: 20px;
  margin-top: 20px;
}

.offer__base-label {
  margin-top: 3px;
  margin-bottom: 15px;
}

.autocompelte__input {
  font-weight: 400;
}

.images__label {
  margin-top: 20px;
  margin-bottom: 20px;
}

.images-section {
  padding: 20px;
  background: $color__default;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-section__image-box {
  width: 168px;
  height: 168px;
  background: $color__default--dark;
  display: flex;
  justify-content: center;
  place-items: center;
}

.image-box__image {
  width: 45px;
  height: 120px;
}

.images__help {
  max-width: 400px;
  margin-top: 20px;
}

.images-upload {
  margin-top: 20px;
}

.upload__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.upload__main-label {
  margin-bottom: 10px;
  color: $color__primary;
}

.description__label {
  margin-top: 20px;
}

.offer__divider {
  margin-bottom: 20px;
}

.offer__box {
  max-width: 590px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.offer__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.offer__buttons--added {
  margin-top: 20px;
}

.offer__actions--added {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.offer__button-add {
  width: 160px;
  justify-self: flex-end;
}

.offer__button-cancel {
  width: 100px;
  justify-self: flex-start;
}

.form__contact-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contact-box__headline {
  margin-bottom: 20px;
}

.contact-box__help {
  padding: 10px;
  background: $color__default;
  border-radius: $border__radius;
  color: $color__black;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.contact-box__text {
  margin-left: 5px;
}

.form__email,
.form__expires-in,
.expires-in__links {
  width: 320px;
}

.expires-in__links {
  margin-top: -12px;
  text-align: right;
  z-index: 1024;

  > a {
    margin-left: 24px;
  }
}

.form__hidden {
  clear: both;
  margin-top: 15px;
}

.form__phone {
  display: flex;
  flex-direction: row;
}

.phone__code {
  width: 120px;
}

.phone__number {
  flex-grow: 1;
  margin-left: 10px;
  max-width: 190px;
}

.form__buy-now__column:first-child {
  margin-right: 20px;
  margin-bottom: 20px;
}

.remove-popup {
  padding: 20px;
}

.remove-popup__content {
  margin-top: 30px;
}

.remove-popup__navi {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

@media all and (min-width: $rwd__sm) {
  .images-section {
    flex-direction: row;
    align-items: center;
  }

  .images__help {
    max-width: 400px;
    margin-top: 20px;
    margin-left: 30px;
  }

  .form__buy-now__row {
    display: flex;
  }

  .form__buy-now__column {
    flex-grow: 1;
    margin-bottom: 0 !important;
  }

  .form__buy-now__column--first {
    margin-top: 20px;
  }
}

@media all and (min-width: $rwd__md) {
  .offer__buttons {
    grid-column: 1/4;
  }

  .offer__actions--added {
    grid-column: 1/4;
  }

  .offer__button-add {
    width: 160px;
  }

  .offer__button-cancel {
    width: 100px;
  }

  .offer__box {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .offer__heading {
    grid-column: 1/3;
  }

  .offer__grid--full {
    grid-column: 1/3;
  }

  .offer__grid--half {
    grid-column: 1/2;
  }

  .offer__form {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
  }

  .form__grid--half {
    grid-column: 1/2;
  }

  .form__grid--full {
    grid-column: 1/4;
  }

  .offer__basic-info {
    grid-column: 1/4;
  }

  .form__cask-type {
    grid-column: 3/4;
  }

  .form__gst {
    grid-column: 3/4;
    max-width: 90px;
  }
}
