@import '../WebApp/styles/shared.module.scss';

.paper {
  display: flex;
  flex-direction: column;
}

.sign-in {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.sign-in__button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sign-in__reset-pass {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-flex;
  align-self: flex-end;
  justify-content: flex-end;
}

.sign-in__facebook,
.sign-in__google,
.sign-in__we-chat {
  margin: 0 0 20px 0;
}

.sign-in__divider {
  display: none;
}

@media all and (min-width: $rwd__md) {
  .paper {
    max-width: 490px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .sign-in {
    margin: 40px 40px 0 40px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .sign-in__socials {
    padding: 0 40px;
  }

  .sign-in__divider {
    margin: 20px 0 30px 0;
    display: block;
  }

  .sign-in__reset-pass {
    margin-top: 0;
    margin-bottom: 0;
    grid-row: 3;
    grid-column: 2;
  }

  .sign-in__button {
    grid-row: 4;
    grid-column: 2;
  }

  .sign-in__email {
    grid-row: 1;
    grid-column: 1/3;
  }

  .sign-in__password {
    grid-row: 2;
    grid-column: 1/3;
  }
}
