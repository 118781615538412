@import '../../../components/WebApp/styles/shared.module.scss';

.background {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.container {
  width: 100%;
  height: 100%;
  position: fixed;
}
