@import '../../components/WebApp/styles/shared.module.scss';

$margin__section: 40px;
$margin__section--search: 20px;
$margin__section__headline: 20px;

.container {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 1290px;
}

@media all and (min-width: $rwd__lg--home) {
  .container {
    margin: 0 auto;
  }
}

.section:not(.section--search) {
  margin-bottom: $margin__section;
}

.section--search {
  margin-bottom: $margin__section--search;
}

.slider__container {
  max-width: 520px;
  margin: 0 auto;
}

.search__headline {
  display: block;
  margin: 32px auto;
  width: 265px;
  height: 128px;
  background: url('../../public/img/home/home--mobile.png') no-repeat;
  background-size: 100% 100%;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .search__headline {
    background: url('../../public/img/home/home--mobile@2x.png') no-repeat;
    background-size: 100% 100%;
  }
}

@media all and (min-width: $rwd__sm) {
  .search__headline {
    width: 636px;
    height: 230px;
    background: url('../../public/img/home/home--tablet.png') no-repeat;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .search__headline {
      background: url('../../public/img/home/home--tablet@2x.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}

@media all and (min-width: $rwd__lg) {
  .search__headline {
    margin: 0;
    width: 481px;
    height: 295px;
    background: url('../../public/img/home/home--desktop.png') no-repeat;
  }
}

.search__headline__text {
  display: none;
}

.search {
  margin-bottom: $margin__section--search;
}

@media screen and (min-width: $rwd__lg) {
  .search,
  .top-searches,
  .advanced-search {
    margin-right: 40px;
  }
}

.top-searches__headline {
  margin-bottom: $margin__section__headline;
}

.top-searches__slider {
  display: flex;
  align-items: center;
  background-color: transparent !important;

  & > :not(:global(.slick-list)) {
    margin-bottom: 10px;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  & > :global(.slick-list) {
    background-color: transparent !important;

    > div > div {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      & > div {
        height: 100%;
      }
    }
  }
}

.top-searches__item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  min-height: 46px;
  margin-bottom: 10px;
}

.top-searches__link {
  display: flex;
  margin: 3px auto;
  max-width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top-searches__text {
  &::before {
    display: inline;
    content: '#';
  }
}

.advanced-search {
  text-align: center;
}

.advanced-search__link {
  display: block;
  line-height: 40px;
  color: $color__primary !important;
}

.section--popular-offers {
  margin-bottom: $margin__section;
}

@media all and (min-width: $rwd__md) {
  .section--popular-offers {
    width: 100%;
    max-width: 1190px;
    margin: 0 auto $margin__section auto;
  }
}

.popular-offers__headline {
  margin-bottom: $margin__section__headline;
}

.popular-offers__show-all {
  margin-top: $margin__section;
  text-align: center;
}

.most-wanted__headline {
  margin-bottom: $margin__section__headline;
}

.most-wanted__item {
  line-height: 2.4rem;
}

.button--show-all {
  width: 100%;
  text-transform: none !important;
}

@media screen and (min-width: $rwd__sm) {
  .button--show-all {
    max-width: 300px;
  }
}

@media screen and (min-width: $rwd__md) {
  .most-wanted__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .most-wanted__item {
    width: 25%;
  }
}

@media screen and (min-width: $rwd__lg) {
  .section--search {
    display: flex;
    flex-direction: row;
  }

  .slider__container {
    order: 2;
    width: 50%;
    max-width: 670px;
  }

  .search__container {
    order: 1;
    width: 50%;
  }
}

.banner {
  width: 100%;
  height: 100%;
}
