@import '../../components/WebApp/styles/shared.module.scss';

.page {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  align-self: center;
  width: 100%;
  word-break: break-word;
}

.logo {
  margin-bottom: 40px;
  width: 80%;
  align-self: center;
  max-width: 560px;
}

.language {
  margin-bottom: 15px;
  align-self: flex-end;
}

.heading {
  margin-bottom: 40px;
}

.subheading {
  margin-bottom: 30px;
}

.list__text {
  margin: 0 0 20px 0;
}

.list {
  list-style: disc;
  margin-left: 10px;
  padding-left: 15px;
}

.list__item {
  margin-bottom: 10px;
}
