@import '../WebApp//styles/shared.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 360px;
  max-width: 80%;
  min-height: 244px;
  justify-content: space-between;
  text-align: center;
  color: #fff;
  font-size: $text__md;
  text-shadow: 1px 1px 1px #000;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  height: 100px;
  justify-content: space-between;
}

@media all and (min-width: $rwd__sm) {
  .content {
    font-size: $text__lg;
  }
}
