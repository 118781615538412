.dialog {
  padding: 20px;
}

.dialog__content {
  margin-top: 30px;
}

.dialog__navigation {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}
