@import '../../WebApp/styles/theme.module.scss';

a.links__item {
  margin: 0 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $color__primary;
  font-weight: 700;
}

.item__img {
  margin-right: 5px;
}
