@import '../WebApp/styles/shared.module.scss';

.gallery {
  font-weight: normal;
}

.gallery__slider {
  margin-bottom: 10px;
}

.gallery__preview-gallery {
  padding: 5px;
  background: $color__default;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
}

.preview-gallery__item {
  margin: 0 2px;
  width: 54px;
  height: 54px;
  cursor: pointer;
}

.gallery--fullscreen {
  height: 100%;
  max-width: 90%;
  max-height: calc(100vh - 108px);
  object-fit: contain;
  margin: 0 auto;
}

.fullscreen__close {
  margin-bottom: 20px;
  align-self: flex-end;
  margin: 10px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

:global {
  $arrowWidth: 60px;
  .slick-arrow:visited,
  .slick-arrow {
    min-width: 10px;
    width: $arrowWidth;
    height: 100%;
    background: $color__default;
    color: $color__primary;
    border-radius: 0;
  }

  .slick-disabled {
    background: $color__default--active;
  }

  .slick-prev::before,
  .slick-next::before {
    content: '';
  }

  .slick-arrow:hover {
    background: $color__default--dark;
  }

  .slick-arrow:active {
    background: $color__default--active;
  }

  .slick-prev,
  .slick-next {
    z-index: 32;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-list {
    margin: 0 auto;
    background: $color__default;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
  .slick-slider {
    background: $color__default;
  }
}
