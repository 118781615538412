@import '../../components/WebApp/styles/shared.module.scss';

.language {
  margin-bottom: 15px;
  align-self: flex-end;
}

.faq {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  align-self: center;
  width: 100%;
  word-break: break-word;
}

.promo {
  margin: 0 auto 40px auto;
  width: 267px;
  height: 722px;
  background: url('../../public/img/faq--mobile.svg') no-repeat;
}

@media all and (min-width: $rwd__sm) {
  .promo {
    margin: 0 auto 50px auto;
    width: 600px;
    height: 665px;
    background: url('../../public/img/faq--tablet.svg') no-repeat;
  }
}

@media all and (min-width: $rwd__lg) {
  .promo {
    margin: 0 auto 60px auto;
    width: 900px;
    height: 598px;
    background: url('../../public/img/faq--desktop.svg') no-repeat;
  }
}

.faq__list {
  max-width: 90%;
  margin: 0 auto;
}

.list__item {
  margin: 16px 0;

  > h3 {
    font-size: $text__4xl;
    text-transform: uppercase;

    & > span {
      color: $color__primary;
      font-size: $text__sm;
    }
  }
}

.item__question {
  line-height: 0.6;
  margin-top: 6px;
  margin-bottom: 16px;
}

.item__icon {
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.faq__newsletter {
  display: flex;
  border: 3px solid $color__primary;
  flex-direction: column;
  padding: 30px;
  margin-top: 70px;
}

.newsletter__text {
  margin: 15px 0;
}

.newsletter__form {
  max-width: 500px;
  align-self: center;
}

.item__body {
  margin: 6px 0 30px 0;
}
