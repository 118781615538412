@import '../WebApp/styles/shared.module.scss';

.paper {
  display: flex;
  flex-direction: column;
}

.reset-password {
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-bottom: 0;
}

.reset-password__button--submit {
  margin-bottom: 30px;
}

@media all and (min-width: $rwd__md) {
  .paper {
    max-width: 490px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .reset-password {
    margin-bottom: 0;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .reset-password__password {
    grid-row: 1;
    grid-column: 1/3;
  }

  .reset-password__password-repeated {
    grid-row: 2;
    grid-column: 1/3;
  }

  .reset-password__button--submit {
    grid-row: 3;
    grid-column: 2;
  }
}
