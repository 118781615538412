@import '../../components/WebApp/styles/shared.module.scss';

$arrow__size: 20px;

.sign-in-page {
  margin-left: 10px;
  margin-right: 10px;
}

@media all and (min-width: $rwd__md) {
  section.sign-in-page {
    margin-left: 0;
    margin-right: 0;
    padding-left: 120px;
    padding-right: 120px;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.new-user {
  margin-top: 20px;
  margin-bottom: 0;
}

.info__container {
  position: relative;
  margin-bottom: 64px;
}

.promo {
  width: 280px;
  height: 78px;
  background: url('../../public/img/sign_in.svg') no-repeat;
  background-size: 100% 100%;
}

.promo__text {
  display: none;
}

.info {
  margin: 30px 0;
}

.info__icon {
  position: absolute;
  top: 275px;
  left: 180px;
  width: 55px;
  height: 55px;
}

.enjoy {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
}

.enjoy__lines {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: $text__md;
  justify-content: center;
  font-weight: bold;
  font-family: 'Futura', sans-serif;
}

.enjoy__arrows {
  display: flex;
  flex-direction: column;
}

.enjoy__arrow {
  width: 0;
  height: 0;
  border-left: $arrow__size solid transparent;
  border-right: $arrow__size solid transparent;
  border-top: $arrow__size solid #000;
  margin-top: 4px;
}

.enjoy__line {
  display: block;
  text-transform: uppercase;
}

.enjoy__line--1 {
  color: $color__primary;
}

.info__line--1 {
  margin-bottom: 0;
}

@media all and (min-width: $rwd__sm) {
  .promo {
    width: 363px;
    height: 101px;
  }

  .info {
    margin-right: 0;
  }
}

@media all and (min-width: $rwd__lg) {
  .content {
    flex-direction: row;
  }

  .form__container {
    order: 1;
    min-width: 400px;
    max-width: 488px;
    width: 100%;
  }

  .info__container {
    order: 2;
    margin-left: 40px;
  }

  .promo {
    margin: 95px 0 0 0;
  }

  .enjoy__arrows {
    flex-direction: row;
  }

  .enjoy__arrow {
    border-top: $arrow__size solid transparent;
    border-bottom: $arrow__size solid transparent;
    border-right: $arrow__size solid #000;
    border-left: none;
    margin-top: 0;
    margin-right: 4px;
  }

  .info {
    width: 360px;
  }
}
