@import '../WebApp/styles/shared.module.scss';

.paper {
  display: flex;
  flex-direction: column;
}

.sign-up {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.sign-up__button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sign-up__reset-pass {
  margin-top: 20px;
  margin-bottom: 40px;
  display: inline-flex;
  align-self: flex-end;
}

.sign-up__facebook,
.sign-up__google,
.sign-up__we-chat {
  margin: 0 0 20px 0;
}

.sign-up__divider {
  display: none;
}

@media all and (min-width: $rwd__md) {
  .paper {
    max-width: 490px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .sign-up {
    margin: 40px 40px 0 40px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .sign-up__socials {
    padding: 0 40px;
  }

  .sign-up__divider {
    margin: 20px 0 30px 0;
    display: block;
  }

  .sign-up__reset-pass {
    margin-top: 20px;
    margin-bottom: 20px;
    grid-row: 3;
    grid-column: 1;
  }

  .sign-up__button {
    grid-row: 7;
    grid-column: 1/3;
  }

  .sign-up__facebook .sign-up__google,
  .sign-up__we-chat {
    margin-bottom: 30px;
  }

  .sign-up__email {
    grid-row: 1;
    grid-column: 1/3;
  }

  .sign-up__password {
    grid-row: 2;
    grid-column: 1/3;
  }

  .sign-up__password-repeated {
    grid-row: 3;
    grid-column: 1/3;
  }

  .sign-up__birthday {
    grid-row: 4;
    grid-column: 1/3;
  }

  .sign-up__newsletter {
    grid-row: 5;
    grid-column: 1/3;
  }

  .sign-up__tos {
    grid-row: 6;
    grid-column: 1/3;
  }
}
