@import './fonts.module.scss';
@import './shared.module.scss';
@import './theme.module.scss';

@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura.ttc') format('truetype');
  font-weight: normal;
}

html {
  font-size: 14px;
}

figure {
  display: inline;
}

html > body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: $color__gray2;
  background-color: #fff;
  min-width: 320px;
  display: block !important;
}
:global #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a,
a:active,
a:visited {
  color: $color__text--default;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

:global {
  a.primary,
  a.primary:active,
  a.primary:visited {
    color: $color__primary;
    text-decoration: none;
  }

  a.primary.text:hover {
    text-decoration: none;
    cursor: default;
  }

  a:hover {
    text-decoration: underline;
  }

  a.transparent,
  a.transparent:active,
  a.transparent:visited {
    color: initial;
    text-decoration: none;
  }

  a.transparent:hover {
    text-decoration: none;
  }

  a.inverted,
  a.inverted:active,
  a.inverted:visited {
    color: $color__default;
    text-decoration: none;
  }

  a.primary.text:hover {
    text-decoration: none;
    cursor: default;
  }
}

:global {
  .gallery-nav--base {
    background: $color__default;
    position: absolute;
    height: 100%;
    width: 60px;
    border-radius: 0;
    padding: 10px;
    min-width: 10px;
  }

  .gallery-nav--right {
    right: 0;
  }

  .gallery-nav--left {
    left: 0;
  }
}

@media all and (min-width: $rwd__md) {
  :global {
    .gallery-nav--base {
      width: 100px;
    }
  }
}
