@import '../../WebApp/styles/shared.module.scss';

.form__input {
  display: block;
  width: 100%;
}

.form__grid--half {
  grid-column: 1/2;
}

.form__grid--full {
  grid-column: 1/4;
}

.contact__box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact__heading {
  margin: 0 0 20px 0;
}

.contact__grid--half {
  grid-column: 1/2;
}

.contact-box__text {
  margin-left: 5px;
}

.captcha-box {
  grid-column: 1/4;
  margin-bottom: 1rem;
}

.contact__grid--full {
  grid-column: 1/4;
}

.contact__tos {
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container--added {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.button-container__button--add {
  margin: 0 auto;
  width: 100%;
  max-width: 280px;
}

@media all and (min-width: $rwd__md) {
  .contact__box {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .contact__heading {
    grid-column: 1/3;
  }

  .contact__form {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
  }

  .form__grid--name {
    grid-column: 1/2 !important;
    grid-row: 1/2;
  }

  .form__grid--email {
    grid-column: 3/4 !important;
    grid-row: 1/2;
  }

  .form__grid--phone {
    grid-column: 3/4 !important;
    grid-row: 2/3;
  }

  .form__grid--topic {
    grid-column: 1/2 !important;
    grid-row: 2/3;
  }

  .form__grid--description {
    grid-column: 1/4 !important;
    grid-row: 3/4;
  }

  .captcha-box {
    grid-column: 1/4 !important;
    grid-row: 4/5;
  }

  .contact__tos {
    grid-column: 1/4 !important;
    grid-row: 5/6;
  }

  .button-container {
    grid-column: 1/4;
    grid-row: 6/7;
  }
}
