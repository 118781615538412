@import '../WebApp//styles/shared.module.scss';

.notification-container {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0%;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  width: 100%;
  align-items: center;
}

.notification-container--reversed {
  flex-direction: column-reverse;
}

:global(.shop.hide) .notification-container {
  display: none !important;
}

.snackbar {
  position: static;
  transform: translateX(0);
  width: 80%;
  max-width: 400px;
  margin-bottom: 10px;
}

.snackbar__content--static {
  box-shadow: unset;
}

.snackbar--bottom-center {
  left: 50%;
}

.snackbar__content--success {
  background-color: $notify__bg--success;
}

.snackbar__content--error {
  background-color: $notify__bg--error;
}

.snackbar__content--info {
  background-color: $notify__bg--info;
}

.snackbar__content--warning {
  background-color: $notify__bg--warning;
}

.notification {
  margin: $spacing-unit;
}

.notification__icon {
  font-size: $text__2xl;
  opacity: 0.9;
}

.notification__icon--left {
  margin-right: 5px;
}

.notification__message {
  display: flex;
  align-items: center;
}

.snackbar__content {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-grow: 1;
  color: theme('snackbar__text');
  width: 100%;
}

.snackbar__action {
  padding-left: 0;
  margin-left: unset;
  margin-left: 5px;
}
