@import '../../../WebApp/styles/shared.module.scss';

.my-account {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px 0 40px 0;
}

.basic__description {
  padding: 10px;
  background: $color__default;
  border-radius: $border__radius;
  color: $color__black;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.description__text {
  margin-left: 5px;
}

.basic__avatar {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: $color__default;
  margin-right: 15px;
  display: block;
}

.basic__user {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.user__name {
  font-weight: 500;
}

.user__type {
  color: $color__gray1;
  font-size: $text__sm;
}

.basic__status {
  display: flex;
  flex-direction: column;
}

.account-type {
  margin-top: 11px;
  margin-bottom: 20px;
}
