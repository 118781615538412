@import '../../components/WebApp/styles/shared.module.scss';

.offers {
  display: flex;
  flex-direction: column;
}

@media all and (min-width: $rwd__md) {
  .content--mine {
    margin-left: 44px;
  }
}

.offers__headline {
  margin-bottom: 20px;
}

.error {
  text-align: center;
}

.search__container {
  margin-bottom: 20px;
  width: 100%;
  max-width: 445px;
  text-align: right;
}

.search__show-all {
  line-height: 40px;
}
