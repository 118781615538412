@import '../../../WebApp/styles/shared.module.scss';
.details {
}

.details__headline {
  margin-bottom: 40px;
}

.details__username-help {
  padding: 10px;
  background: $color__default;
  border-radius: $border__radius;
  color: $color__black;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.username-help__text {
  margin-left: 5px;
}
