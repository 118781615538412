.link--silent,
.link--silent a {
  color: inherit !important;
}

.link--silent:active,
.link--silent:visited,
.link--silent a:active,
.link--silent a:visited {
  text-decoration: none;
}

.link--silent:hover,
.link--silent a:hover {
  text-decoration: none;
}
