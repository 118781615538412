@import '../../WebApp/styles/shared.module.scss';

.conversation {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
}

.conversation__headline {
  margin-bottom: 40px;
}

.conversation__nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.nav__item {
  margin-right: 20px;
}

.conversation__messages {
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: flex-end;
  margin-bottom: 20px;
}

.message--primary {
  align-self: flex-start;
}

.message__author {
  display: flex;
  margin-bottom: 4px;
}

.message__content {
  padding: 10px;
  border-radius: $border__radius;
  background: $color__default;
  color: $color__text--default;
}

.author__name {
  font-size: $text__sm;
  color: $color__black;
  margin-right: 10px;
}

.author__date {
  font-size: $text__sm;
  color: $color__gray1;
}

.message__content--primary {
  background: $color__primary;
  color: $color__text--primary;
}

.conversation__divider {
  margin: 20px 0;
}

.conversation__actions {
  display: flex;
  justify-content: space-between;
}

.actions__back {
  width: 100px;
}

.actions__send {
  width: 140px;
}

.form__field {
  margin-bottom: 40px;
}
