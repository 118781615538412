@import '../WebApp/styles/shared.module.scss';

.item {
  width: 100%;
  max-width: 280px;
  border: 1px solid $color__default--dark;
  position: relative;
}

.item__image {
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 10px;
  object-fit: cover;
}
