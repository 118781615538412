@import '../../components/WebApp/styles/shared.module.scss';

.text--center {
  text-align: center;
}

.page {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  align-self: center;
  width: 100%;
  word-break: break-word;
}

.logo {
  margin-bottom: 40px;
  width: 80%;
  align-self: center;
  max-width: 560px;
}

.language {
  margin-bottom: 15px;
  align-self: flex-end;
}

.heading {
  margin-bottom: 40px;
}

.subheading {
  margin-bottom: 30px;
}

.caption {
  margin-bottom: 20px;
}

.list__text {
  margin: 0 0 20px 0;
}

.tos {
  > ol {
    padding-left: 15px;
    margin-left: 15px;

    > li {
      > div,
      > p {
        margin-left: -10px;
      }

      &::before {
        width: 25px !important;
      }

      &.normal p {
        font-weight: 400;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    > li {
      margin-bottom: 20px;
      font-weight: 700;

      &:last-child {
        margin-bottom: 0;
      }

      ol,
      div,
      p {
        margin-top: 0.5rem;
      }

      li {
        font-weight: 400;
        margin-bottom: 10px;
      }
    }

    li {
      padding-left: 25px;

      &::before {
        display: inline-block;
        margin-left: -35px;
        width: 35px;
        content: counters(item, '.') '. ';
        counter-increment: item;
      }
    }
  }
}

.list {
  list-style: disc;
  margin-left: 10px;
  padding-left: 15px;
}

.list__item {
  margin-bottom: 10px;
}

.delimiter {
  width: 100%;
}
