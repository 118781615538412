@import '../../WebApp/styles/shared.module.scss';

.item {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 10px;
  border: 1px solid $color__default--dark;
  align-items: center;
}

.item__avatar {
  display: none;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: $color__default;
}

.item__username {
  font-size: $text__sm;
  margin-left: 10px;
}

.item__offer-title {
  color: $color__black;
  font-size: $text__sm;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item__user {
  min-width: 70px;
  margin-right: 10px;
}

.item__details {
  width: 30px;
  position: relative;
}

.item__offer {
  display: flex;
  flex-direction: column;
  flex-basis: 85%;
  overflow: hidden;
  margin-right: 20px;
}

.item__message-first-line {
  color: $color__gray1;
  font-size: $text__sm;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.details__date {
  display: none;
  text-transform: capitalize;
  font-size: $text__sm;
}

.details__counter {
  height: 16px;
}

.details__unread {
  color: $color__primary;
  font-size: $text__sm;
  position: relative;
  display: none;
  margin-top: 3px;
}

.details__counter-box {
  position: absolute;
  right: 2px;
  top: -10px;
}

@media all and (min-width: $rwd__md) {
  .details__counter-box {
    position: absolute;
    right: -3px;
    top: -4px;
  }

  .item {
    padding: 17px;
  }

  .details__date,
  .item__avatar,
  .details__unread {
    display: flex;
  }

  .details__badge {
    left: 5px;
  }

  .item__details {
    width: 103px;
  }

  .item__offer-title {
    width: 100%;
  }

  .item__user {
    display: flex;
    align-items: center;
    min-width: 125px;
  }

  .item__offer {
    flex-basis: 55%;
  }
}
