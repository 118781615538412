@import '../../WebApp/styles/shared.module.scss';

.newsletter {
  display: flex;
  flex-direction: column;
}

.newsletter__headline {
  margin-bottom: 40px;
}

.button-save {
  width: 100%;
  margin-top: 60px;
}

@media all and (min-width: $rwd__sm) {
  .button-save {
    max-width: 150px;
    align-self: flex-end;
  }

  .newsletter {
    max-width: 590px;
    margin: 0 auto;
  }
}
