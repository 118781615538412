@import '../../WebApp/styles/shared.module.scss';
@import '../../WebApp/styles/mixin.module.scss';

.container {
  position: relative;
}

.container--full-width {
  width: 100%;
}

.suggestions__container--open {
  z-index: 1;
  margin-top: $spacing-unit;
  left: 0;
  right: 0;
}

.suggestion {
  display: block;
}

.suggestions__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 35vh;
  overflow-y: auto;
}

.input {
  width: 100%;
  flex-grow: 1;
}

.autocomplete--with-margins {
  @include form-field__margins;
}

.autocomplete--with-margins :global(.WM-MuiFormHelperText-root) {
  @include form-field__margins--with-helper;
}

.value {
  font-weight: 400;
}
