@import '../WebApp/styles/shared.module.scss';

@keyframes deletingItem {
  0% {
    display: flex;
    opacity: 1;
  }

  50% {
    display: flex;
    opacity: 0.5;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    visibility: collapse;
  }
}

.item__row {
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  flex-direction: row;
  border: 1px solid $color__default--dark;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.item__checkbox {
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -11px;
}

.item--deleted {
  display: none !important;
}

.item__photo {
  min-width: 110px;
  width: 110px;
  height: 110px;
  display: block;
  position: relative;
}

.photo__image {
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 10px;
  object-fit: cover;
}

.photo__image--inactive {
  filter: grayscale(100%);
}

.photo__icon--hidden {
  position: absolute;
  top: 8px;
  right: 8px;
}

.photo__country {
  background: rgba($color__primary, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px 10px;
}

.country__flag {
  height: 14px;
  width: 16px;
}

.country__name {
  margin-left: 4px;
  color: #fff;
  font-size: $text__sm;
}

.item__info {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 110px);
  justify-content: space-between;
  flex-grow: 1;
}

.item__actions {
  display: flex;
}

.info__title {
  font-weight: 500;
  letter-spacing: 0;
  color: $color__text--default;
}

.info__subtitle {
  color: $color__gray1;
  font-size: $text__sm;
  letter-spacing: 0;
}

.item__price {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: right;
  white-space: nowrap;
}

.price__gross {
  margin-bottom: 6px;
}

.item__divider {
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
}

.availability__icon {
  margin-right: 5px;
}

.item__nav--top {
  display: none;

  .nav__item {
    padding: 5px 40px 0 0;
  }
}

.item__nav--bottom {
  display: block;
  flex-grow: 1;
  flex-basis: 100%;


  .nav__items {
    flex-grow: 1;
    justify-content: space-around;
  }

  .nav__item {
    padding: 10px 40px 10px 0;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.nav__checkbox {
  margin-right: 10px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__items {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.navi__item {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}

.nav__link {
  display: flex;
  flex-direction: row;
}

.nav__link__icon {
  margin-right: 4px;
}

.share-popover {
  padding: 10px;
  min-width: 230px;
}

.share-popover__headline {
  margin-bottom: 15px;
}

.share-popover__share-icons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.share-icons__item {
  margin-right: 10px;
  cursor: pointer;
}

.share__mail {
  height: 24px;
}

.remove-popup {
  padding: 20px;
}

.remove-popup__content {
  margin-top: 30px;
}

.remove-popup__navi {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

@media all and (min-width: $rwd__sm) {
  .item__nav--top {
    display: block;
  }

  .item__nav--bottom {
    display: none;
  }
}

@media all and (min-width: 640px) {
  .item__info {
    width: auto;
  }
}
