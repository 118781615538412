.search {
  width: 100%;
  height: 40px;
}

.search__icon {
  cursor: pointer;
  z-index: 1000;
}

.suggestions__list {
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: 338px;
  overflow-x: hidden;
  overflow-y: scroll;
}
