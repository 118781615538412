@import '../../../WebApp/styles/shared.module.scss';

.dialog {
  min-width: 250px;
}

.headline {
  margin-bottom: 40px;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation__button-save {
  width: 140px;
}

@media all and (min-width: $rwd__sm) {
  .dialog {
    min-width: 560px;
  }
}
