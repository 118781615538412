@import '../../WebApp/styles/shared.module.scss';

.google {
  background: #fff;
  border: 1px solid $color__gray3;
}
.google__icon {
  height: 24px;
}

.google:active,
.google:focus,
.google:hover {
  background: #eee;
}
