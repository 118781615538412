@import '../WebApp/styles/shared.module.scss';

.upload-box {
  width: 135px;
  height: 135px;
  background: $color__default;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color__default--dark;
  border-radius: $border__radius;
  margin: 5px;
  position: relative;
}

.upload-box--primary {
  border: 1px solid $color__primary;
}

.upload-box__icon-add {
  height: 20px;
  width: 20px;
}

.upload-box__input {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.upload-box__preview {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.avatar-box {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar-box__input {
  display: none;
}

.avatar-box__button {
  width: 110px;
  margin-left: 20px;
  height: 40px;
}

.avatar-box__preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  background: $color__default;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
}

.upload-box__icon-delete {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 4px;
  z-index: 16;
  border-left: 1px solid $color__primary;
  border-bottom: 1px solid $color__primary;
  cursor: pointer;
}

.avatar-box__input--disabled {
  width: 0;
  height: 0;
  visibility: hidden;
}

@media all and (min-width: $rwd__md) {
  .upload-box {
    width: 105px;
    height: 105px;
    margin-top: 15px;
  }

  .upload-box--primary {
    width: 120px;
    height: 120px;
    margin-top: 0;
  }
}
