@import '../WebApp/styles/shared.module.scss';

.item {
  width: 100%;
  max-width: 280px;
  border: 1px solid $color__default--dark;
  position: relative;
}

.item__photo {
  width: 100%;
  height: 372px;
  display: block;
  position: relative;
  border-bottom: 1px solid $color__default--dark;
}

.item__image {
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 10px;
  object-fit: cover;
}

.item__image--inactive {
  filter: grayscale(100%);
}

.details__title {
  min-height: 45px;
}

@mixin item_button {
  background: $color__gray5;
  border-radius: 7px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  position: absolute;
  top: 10px;
  z-index: 100;
}

.item__favorite {
  @include item_button;
  right: 10px;
}

.item__list {
  @include item_button;
  right: 44px;
}

@mixin action_icon {
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.favorite__icon {
  background-image: url('/public/icons/favorite.svg');
  @include action_icon;
}

.item__favorite:hover .favorite__icon {
  background-image: url('/public/icons/favorite_active.svg');
}

.list__icon {
  background-image: url('/public/icons/list.svg');
  @include action_icon;
}

.item__list:hover .list__icon {
  background-image: url('/public/icons/list_active.svg');
}

.item__country {
  background: rgba($color__primary, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 7px;
}

.item__details {
  padding: 10px;
}

.item__divider {
  margin: 10px 0;
}

.details__price {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
}

.price__gross {
  margin-bottom: 6px;
}

.details__offer {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.details__box {
  display: flex;
}

.details__row {
  font-size: $text__sm;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.details__icon {
  margin-right: 5px;
}

.user__row {
  display: flex;
  flex-direction: row;
}

.country__name {
  margin-left: 4px;
  color: #fff;
}

.user__username {
  width: 100%;
}

.item__badge__container {
  position: absolute;
  top: 0;
  right: 0;
  width: 140px;
  height: 140px;
  overflow: hidden;
}

.item__badge {
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  border-radius: 0;
  transform: rotate(45deg);
}

.item__badge__label {
  position: absolute;
  top: 98px;
  width: 50px;
  line-height: 1.2;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.item__badge__label--last-call {
  top: 98px;
}

.item__badge__label--archived {
  top: 113px;
}
