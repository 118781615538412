@import '../../../WebApp/styles/shared.module.scss';

.form__location {
  display: flex;
  flex-direction: row;
}

.location__postCode {
  width: 90px;
}

.location__city {
  margin-left: 10px;
  flex-grow: 1;
}

.form__phone {
  display: flex;
  flex-direction: row;
}

.phone__code {
  width: 120px;
}

.phone__number {
  margin-left: 10px;
  flex-grow: 1;
}

@media all and (min-width: $rwd__sm) {
  .phone__code {
    width: 200px;
  }

  .location__postCode {
    width: 200px;
  }
}
