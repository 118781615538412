@import '../../../components/WebApp/styles/shared.module.scss';

.header {
  height: auto;
  min-height: 520px;
  flex-grow: 1;
}

.container {
  display: none;
}

.content {
  margin: 0 auto;
  display: block;
  max-width: 300px;
  text-align: center;
}

.notification {
  margin-bottom: 1.5rem;
}
