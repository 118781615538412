@import '../../components/WebApp/styles/shared.module.scss';

$arrow__size: 20px;

.sign-up-page {
  margin-left: 10px;
  margin-right: 10px;
}

.already-exist {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media all and (min-width: $rwd__md) {
  section.sign-up-page {
    margin-left: 0;
    margin-right: 0;
    padding-left: 120px;
    padding-right: 120px;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.info__container {
  position: relative;
  margin-bottom: 64px;
}

.promo {
  width: 270px;
  height: 328px;
  background: url('../../public/img/sign_up--mobile.png') no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .promo {
    background: url('../../public/img/sign_up--mobile@2x.png') no-repeat;
    background-size: 100% 100%;
  }
}

.promo__text {
  display: none;
}

.info__icon {
  position: absolute;
  top: 275px;
  left: 180px;
  width: 55px;
  height: 55px;
}

.become {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
}

.become__lines {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: $text__md;
  justify-content: center;
  font-weight: bold;
  font-family: 'Futura', sans-serif;
}

.become__arrows {
  display: flex;
  flex-direction: column;
}

.become__arrow {
  width: 0;
  height: 0;
  border-left: $arrow__size solid transparent;
  border-right: $arrow__size solid transparent;
  border-top: $arrow__size solid #000;
  margin-top: 4px;
}

.become__line {
  display: block;
  text-transform: uppercase;
}

.become__line--2 {
  color: $color__primary;
}

.info__line--1 {
  margin-bottom: 20px;
}

@media all and (min-width: $rwd__sm) {
  .promo {
    width: 601px;
    height: 286px;
    background: url('../../public/img/sign_up--desktop.png') no-repeat;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .promo {
      background: url('../../public/img/sign_up--desktop@2x.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .info {
    margin-right: 150px;
  }

  .info__icon {
    top: auto;
    left: auto;
    right: 10px;
    bottom: -20px;
    width: 125px;
    height: 125px;
  }
}

@media all and (min-width: $rwd__lg) {
  .content {
    flex-direction: row;
  }

  .form__container {
    order: 1;
    min-width: 400px;
    max-width: 488px;
    width: 100%;
  }

  .info__container {
    order: 2;
    margin-left: 40px;
  }

  .promo {
    margin: 128px 0 0 0;
  }

  .become__arrows {
    flex-direction: row;
  }

  .become__arrow {
    border-top: $arrow__size solid transparent;
    border-bottom: $arrow__size solid transparent;
    border-right: $arrow__size solid #000;
    border-left: none;
    margin-top: 0;
    margin-right: 4px;
  }

  .info {
    margin-right: 0;
    width: 360px;
  }

  .info__icon {
    top: auto;
    left: auto;
    right: 0;
    bottom: 45px;
    width: 150px;
    height: 150px;
  }
}
