@import '../../../WebApp/styles/shared.module.scss';

.information__headline {
  margin-bottom: 20px;
  margin-top: 40px;
}

.information__hint {
  margin: 10px 0 40px 0;
}

.photo-box {
  margin-bottom: 40px;
}

.photo-box__headline {
  font-weight: 500;
  margin-bottom: 10px;
}

.photo-box__upload {
  height: 80px;
  width: 80px;
  background: $color__default;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 5px;
  position: relative;
}
