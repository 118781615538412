@import '../../../components/WebApp/styles/shared.module.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  max-width: 2000px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.header::before {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('/public/lp/bg.jpg');
  background-size: cover;
  background-position-x: right;
  opacity: 0.6;
  position: absolute;
}

.header__links {
  position: absolute;
  right: 0;
  top: 15px;
  display: none !important;
}

.header__logo {
  width: 265px;
  position: absolute;
  top: 55px;
  left: calc(50% - 132.5px);
}

.header__text-block {
  background: rgba(255, 255, 255, 0.705);
  padding: 20px 10px;
  position: absolute;
  z-index: 100;
  top: 290px;
  width: 100%;
}

.text-block__heading {
  color: #f2411b;
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
}

.text-block__description {
  color: #383838;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  max-width: 680px;
  margin: 0 auto;
}

.section {
  flex-grow: 1;
}

@media all and (min-width: $rwd__sm) {
  .header__logo {
    width: 365px;
    position: absolute;
    top: 55px;
    left: calc(50% - 182.5px);
  }
}

@media all and (min-width: $rwd__md) {
  .header__logo {
    width: 565px;
    position: absolute;
    top: 55px;
    left: calc(50% - 282.5px);
  }

  .header::before {
    opacity: 0.8;
  }

  .text-block__description {
    font-size: 18px;
  }

  .text-block__heading {
    font-size: 38px;
  }

  .header__text-block {
    background: unset;
  }
}

@media all and (min-width: $rwd__md) {
  .home-page {
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
    padding: 0 15px;
  }
}
