@import '../../components/WebApp/styles/shared.module.scss';

.page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  align-self: center;
  width: 100%;
  word-break: break-word;
}

.title {
  margin-bottom: 2rem;
}

.logo {
  display: block;
  margin: 0 auto 2rem auto;
  width: 80%;
  max-width: 560px;
  align-self: center;
}

.features-circle__info {
  margin-bottom: 2rem;
  text-align: center;
}

.features-circle__button {
  display: none;
}

.features-list {
  display: grid;
  width: 280px;
  margin: 1rem auto 3rem auto;
  grid-template-columns: auto auto auto auto;
  list-style-type: none;
  row-gap: 9px;
}

.features-list__item img {
  width: 62px;
  height: 62px;
}

.feature {
  margin-bottom: 2rem;
}

.feature__desc {
  margin-top: 0.5rem;
}

.features-circle__button,
.button {
  margin: 0 auto;
  max-width: 280px;
}

.button {
  display: block;
}

@media all and (min-width: $rwd__sm) {
  .container {
    display: grid;
    grid-template-columns: auto 355px auto;
    grid-template-rows: auto 349px auto auto;
    grid-gap: 2rem;
  }

  .logo,
  .features-circle__info,
  .features-list {
    display: none;
  }

  .features-circle,
  .feature {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .features-circle {
    display: block;
    margin: 0 auto;
    width: 355px;
    height: 349px;
    background-image: url('/public/img/buy/circle--tablet.svg');
    background-repeat: no-repeat;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .feature {
    max-width: 280px;
  }

  .feature--all-in-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .feature--buy-and-sell {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .feature--quick-and-easy {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .feature--secure {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .button {
    margin-top: 1rem;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}

@media all and (min-width: $rwd__md) {
  .container {
    grid-template-columns: auto 717px auto;
    grid-template-rows: auto 717px auto auto;
  }

  .features-list__item img {
    width: 122px;
    height: 122px;
  }

  .features-circle {
    display: flex;
    background-image: url('/public/img/buy/circle--desktop.svg');
    width: 717px;
    height: 717px;
  }

  .button {
    display: none;
  }

  .logo,
  .features-circle__info,
  .features-circle__button {
    display: block;
  }

  .logo {
    width: 393px;
    height: 82px;
  }

  .features-circle__info {
    max-width: 270px;
  }
}
